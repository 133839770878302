@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variables.scss';

@import 'layouts/fading.scss';
@import 'layouts/shadow.scss';

@import 'components/loading-overlay.scss';
@import 'components/badge.scss';
@import 'components/button.scss';
@import 'components/input.scss';
@import 'components/image-select.scss';
@import 'components/react-modal.scss';

:root {
  
}

body {
   
}

.test-line {
    border: 1px solid red;
}

.test-line2 {
    border: 1px solid green;
}

.h-sidenav {
    height: calc(100vh - 200px);
}