.fade-in {
    animation: 0.5s anim-fade-in forwards;
}

@keyframes anim-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}