.ReactModal {
    &__Overlay {
        opacity: 0;
        transition: opacity 0.15s;
        background-color: rgba(150,150,150,0.5) !important;
        &--after-open {
            opacity: 1;
        }
        &--before-close{
            opacity: 0;
        }   
    }

    &__Content {
        color: theme('colors.slate.800') !important;
        background-color: white !important;
        border: 1px solid rgba(100,100,100,0.35) !important;
        border-radius: 10px !important;
        -webkit-box-shadow: 0px 0px 14px 3px rgba(0,0,0,0.15) !important;
        -moz-box-shadow: 0px 0px 14px 3px rgba(0,0,0,0.15) !important;
        box-shadow: 0px 0px 14px 3px rgba(0,0,0,0.15) !important;

        max-width: 100% !important;
        max-height: 100% !important;
    }
}