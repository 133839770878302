.badge {
    @apply text-xs font-medium mr-2 px-1.5 py-0.5 rounded;
    &-default {
        @apply bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300 border border-blue-400;
    }
    &-dark {
        @apply bg-gray-100 text-gray-800 text-xs dark:bg-gray-700 dark:text-gray-300 border border-gray-500;
    }
    &-red {
        @apply bg-red-100 text-red-800 text-xs dark:bg-red-900 dark:text-red-300 border border-red-400;
    }
    &-green {
        @apply bg-green-100 text-green-800 text-xs dark:bg-green-900 dark:text-green-300 border border-green-400;
    }
    &-yellow {
        @apply bg-yellow-100 text-yellow-800 text-xs dark:bg-yellow-900 dark:text-yellow-300 border border-yellow-300;
    }
    &-indigo {
        @apply bg-indigo-100 text-indigo-800 text-xs dark:bg-indigo-900 dark:text-indigo-300 border border-indigo-400;
    }
    &-purple {
        @apply bg-purple-100 text-purple-800 text-xs dark:bg-purple-900 dark:text-purple-300 border border-purple-400;
    }
    &-pink {
        @apply bg-pink-100 text-pink-800 text-xs dark:bg-pink-900 dark:text-pink-300 border border-pink-400;
    }

    &-lg {
        @apply text-sm;
    }
}