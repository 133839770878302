.input {
    
    &-textbox {
        @apply focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 transition-all focus:border-fuchsia-300 focus:outline-none focus:transition-shadow;
    }

    &-disabled {
        @apply bg-slate-100;
    }

    &-select2 > div {
        border-radius: 0.5rem;
    }

    &-checkbox {
        @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2;
    }
}