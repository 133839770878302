.image-select {
    transition: .2s all;
    &-portrait {
        aspect-ratio: 2 / 3;
        border-radius: 5px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    &-avatar {
        aspect-ratio: 1 / 1;
        border-radius: 5px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    &-landscape {
        aspect-ratio: 16 / 9;
        border-radius: 5px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    &-none {
        border: 2px dashed theme('colors.slate.400');
        position: relative;

        & > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:hover {
            border: 2px dashed theme('colors.slate.700');
            color: theme('colors.slate.700');
        }
    }
}