.btn {
    @apply transition-all inline-block px-3 py-2 font-bold text-center uppercase align-middle transition-all bg-transparent rounded-lg cursor-pointer leading-pro text-xs ease-soft-in shadow-soft-md bg-150 bg-gradient-to-tl hover:shadow-soft-xs active:opacity-85 tracking-tight-soft bg-x-25;

    &-aurora {
        @apply text-white from-gray-900 to-slate-800 hover:from-gray-800 hover:to-slate-900;
    }

    &-red {
        @apply text-white from-red-900 to-red-500 hover:from-red-900 hover:to-red-700;
    }

    &-blue {
        @apply text-white from-blue-900 to-blue-500;
    }

    /*
    &-green {
        @apply text-white bg-green-600 hover:bg-green-700 active:bg-green-800 focus:ring-green-700 disabled:bg-gray-600 disabled:text-gray-800 disabled:cursor-not-allowed;
    }

    

    &-red {
        @apply text-white bg-red-600 hover:bg-red-700 active:bg-red-800 focus:ring-red-700 disabled:bg-gray-600 disabled:text-gray-800 disabled:cursor-not-allowed;
    }

    &-yellow {
        @apply text-gray-800 bg-yellow-300 hover:bg-yellow-400 active:bg-yellow-500 focus:ring-yellow-400 disabled:bg-gray-600 disabled:cursor-not-allowed;
    }

    &-pink {
        @apply text-white bg-pink-600 hover:bg-pink-700 active:bg-pink-800 focus:ring-pink-700 disabled:bg-gray-600 disabled:text-gray-800 disabled:cursor-not-allowed;
    }

    &-white {
        @apply text-black bg-white hover:bg-gray-200 active:bg-gray-300 focus:ring-blue-700 disabled:bg-gray-600 disabled:text-gray-800 disabled:cursor-not-allowed;
    }*/

    &-sm {
        @apply px-3 py-2;
    }

    &-keep-size {
        @apply px-5 py-3;
    }

    @media (min-width: $l-sm) {
        @apply px-5 py-3;

        &-sm {
            @apply px-3 py-2;
        }
    }
}